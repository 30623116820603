

























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class LineTextArea extends Vue {
  @Prop() label!: string
  @Prop() name!: string
  @Prop() amount!: number
  @Prop() setlines!: number
  @Prop() linemax!: number
  @Prop() showlong!: string
  @Prop({ default: 'text' }) type!: string
  @Prop() value!: string
  @Prop() placeholder!: string
  @Prop() description!: string
  @Prop() rows!: number
  @Prop() minAmount!: number
  @Prop() validation!: string | unknown

  private localValidation: string | unknown = '';

  data () {
    return {
      localValidation: this.validation
    }
  }

  mounted () {
    this.lineCounter()
  }

  @Watch('value', { deep: true })
  lineCounter () {
    let textboxLineLength = 0
    const current = document.getElementById(this.name + 'countLines')
    const tooLong = document.getElementById(this.name + 'countLength')
    const tooLongLines = []
    if (this.value !== undefined && this.value !== null) {
      textboxLineLength = this.value.split(/\r\n|\r|\n/).length
    }
    if (this.setlines !== undefined) {
      textboxLineLength += this.setlines
    }
    if (current) {
      current.innerText = 'amount: ' + String(textboxLineLength) + '/' + String(this.amount)
      if (textboxLineLength < this.minAmount) {
        current.style.color = 'red'
        current.style.color = 'red'
      } else if (textboxLineLength > this.amount) {
        current.style.color = 'red'
        current.style.color = 'red'
      } else {
        current.style.color = 'black'
        current.style.color = 'black'
      }
    }

    if (this.value !== undefined && this.value !== null) {
      const values = this.value.split(/\r\n|\r|\n/)
      for (let i = 0; i < textboxLineLength; i++) {
        if (values[i] && values[i].length > this.linemax) {
          tooLongLines.push(i + 1)
        }
      }
    }

    if (tooLong) {
      tooLong.style.color = 'red'
      tooLong.innerText = tooLongLines.toString()
    }

    if (typeof this.value === 'string' && this.value.includes('!')) {
      this.localValidation = '\'There is an exclamation mark, be careful as it may not get approved by Google (disapproval punctuation)\''
    } else {
      this.localValidation = ''
    }
  }
}
