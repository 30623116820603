export class ShowCpcDropdown {
  // TODO: can be cleaner
  hideCPA () {
    if (document.querySelector('#bidStrategyDropdown').getElementsByTagName('select')[0].value !== 'Target CPA') {
      document.querySelector('.targetCpa').classList.add('hidden')
      document.querySelector('.maxCpc').classList.remove('hidden')
    } else {
      document.querySelector('.targetCpa').classList.remove('hidden')
      document.querySelector('.maxCpc').classList.add('hidden')
    }
  }

  checkDropdownValue () {
    if (document.querySelector('#bidStrategyDropdown')) {
      if ((document.querySelector('#bidStrategyDropdown').getElementsByTagName('select')[0].value !== null)) {
        if (document.querySelector('#bidStrategyDropdown').getElementsByTagName('select')[0].value !== 'Target CPA') {
          document.querySelector('.targetCpa').classList.add('hidden')
          document.querySelector('.maxCpc').classList.remove('hidden')
        }
        document.querySelector('#bidStrategyDropdown').getElementsByTagName('select')[0].addEventListener('change', this.hideCPA)
      }
    }
  }
}
