
































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { object, number, array, string } from 'yup'
import {
  SubTitle,
  Title,
  Label,
  Nested,
  Dropdown,
  SearchSelect,
  CheckGroup,
  Check,
  Validation,
  RadioGroup,
  SearchTreeSelect,
  Errors,
  Textbox
} from '../../../lib/mavejs'
import { Functions } from '../../../mixins/Functions'
import Vuex from 'vuex'
import { SearchCampaign } from '@/interfaces/form/SearchCampaign'

@Component({
  components: {
    SubTitle,
    Title,
    Nested,
    Label,
    Dropdown,
    Validation,
    SearchSelect,
    CheckGroup,
    Check,
    RadioGroup,
    SearchTreeSelect,
    Textbox
  },
  mixins: [Functions, Errors]
})

export default class SearchOptionsForm extends Vue {
  @Prop() store!: SearchCampaign;

  model = this.store.settings
  baseModel = this.store.campaign
  count = 1

  mounted () {
    if (this.model.discountAmount == null) {
      this.model.discountAmount = 0
    }
  }

  // TODO: make discountAmount non nullable
  schema = object({
    xreplace: string().required()
  })
}
