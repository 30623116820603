























































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import {
  Title,
  SubTitle,
  Textbox,
  AltCard,
  Copy,
  Validation,
  Check,
  Button,
  OverlayLink,
  Errors,
  s,
  Action,
  TextboxWithCharlimit,
  OverlayLinkMultiple,
  Dropdown,
  Label,
  LineTextArea,
  Nested,
  SearchTreeSelect
} from '../../../lib/mavejs'
import AdsTemplate from '../../../components/campaigns/form/AdsTemplate.vue'
import { Functions } from '@/mixins/Functions'
import { array, object, string } from 'yup'
import { alphabetical, youtube } from '@/functions'
import { AdData } from '@/classes/ads/AdData'
import { UrlTools } from '@/classes/UrlTools'

import { Youtube } from '@/classes/ads/Youtube'
import { PasteMultiple } from '@/classes/ads/PasteMultiple'
import { forEach, toUpper } from 'lodash'
import { SearchCampaign } from '@/interfaces/form/SearchCampaign'
import LineTextAreaExWarning from '@/lib/mavejs/components/form/LineTextAreaExWarning.vue'

@Component({
  components: {
    LineTextAreaExWarning,
    Title,
    SubTitle,
    Textbox,
    AltCard,
    Copy,
    Validation,
    Check,
    Button,
    OverlayLink,
    AdsTemplate,
    Action,
    TextboxWithCharlimit,
    OverlayLinkMultiple,
    Dropdown,
    Label,
    LineTextArea,
    Nested,
    SearchTreeSelect
  },
  mixins: [Functions, Errors],
  methods: { youtube: youtube, s: s, alphabetical: alphabetical }
})
export default class SearchAdsForm extends Vue {
  @Prop() store!: SearchCampaign;
  settingsModel = this.store.settings
  geos = this.store.geos
  template = [
    {
      id: 0,
      name: 'Global',
      isGlobal: true,
      ads: [],
      description: '',
      longHeadline: '',
      url: '',
      countriesWithLanguage: []
    },
    {
      id: 1,
      name: 'French',
      isGlobal: false,
      ads: [],
      description: '',
      longHeadline: '',
      url: '',
      countriesWithLanguage: ['FR', 'BEFR', 'CAFR', 'CHFR']
    },
    {
      id: 2,
      name: 'English',
      isGlobal: false,
      ads: [],
      description: '',
      longHeadline: '',
      url: '',
      countriesWithLanguage: ['EN', 'US', 'CAEN', 'EGEN', 'HKEN', 'IE', 'ILEN', 'INEN', 'JM', 'MYEN', 'NGEN', 'NZ', 'PHEN', 'SGEN', 'THEN', 'ZAEN']
    },
    {
      id: 3,
      name: 'Hebrew',
      isGlobal: false,
      ads: [],
      description: '',
      longHeadline: '',
      url: '',
      countriesWithLanguage: ['IW', 'ILHE']
    },
    {
      id: 4,
      name: 'Spanish',
      isGlobal: false,
      ads: [],
      description: '',
      longHeadline: '',
      url: '',
      countriesWithLanguage: ['ES', 'AR', 'CL', 'CO', 'MX', 'PE', 'PR', 'USES']
    },
    {
      id: 5,
      name: 'Japanese',
      isGlobal: false,
      ads: [],
      description: '',
      longHeadline: '',
      url: '',
      countriesWithLanguage: ['JA']
    },
    {
      id: 6,
      name: 'Korean',
      isGlobal: false,
      ads: [],
      description: '',
      longHeadline: '',
      url: '',
      countriesWithLanguage: ['KO', 'KR']
    },
    {
      id: 7,
      name: 'Danish',
      isGlobal: false,
      ads: [],
      description: '',
      longHeadline: '',
      url: '',
      countriesWithLanguage: ['DA', 'DK']
    },
    {
      id: 8,
      name: 'Norwegian',
      isGlobal: false,
      ads: [],
      description: '',
      longHeadline: '',
      url: '',
      countriesWithLanguage: ['NO']
    },
    {
      id: 9,
      name: 'Swedish',
      isGlobal: false,
      ads: [],
      description: '',
      longHeadline: '',
      url: '',
      countriesWithLanguage: ['SV', 'SE']
    },
    {
      id: 10,
      name: 'Italian',
      isGlobal: false,
      ads: [],
      description: '',
      longHeadline: '',
      url: '',
      countriesWithLanguage: ['IT']
    },
    {
      id: 11,
      name: 'Brazil/Portu',
      isGlobal: false,
      ads: [],
      description: '',
      longHeadline: '',
      url: '',
      countriesWithLanguage: ['PT', 'BR']
    },
    {
      id: 12,
      name: 'Dutch',
      isGlobal: false,
      ads: [],
      description: '',
      longHeadline: '',
      url: '',
      countriesWithLanguage: ['NL', 'BENL']
    },
    {
      id: 13,
      name: 'German',
      isGlobal: false,
      ads: [],
      description: '',
      longHeadline: '',
      url: '',
      countriesWithLanguage: ['DE', 'AT', 'CHDE']
    },
    {
      id: 14,
      name: 'Finish',
      isGlobal: false,
      ads: [],
      description: '',
      longHeadline: '',
      url: '',
      countriesWithLanguage: ['FI']
    },
    {
      id: 15,
      name: 'Czech',
      isGlobal: false,
      ads: [],
      description: '',
      longHeadline: '',
      url: '',
      countriesWithLanguage: ['CZ']
    },
    {
      id: 16,
      name: 'Chinese',
      isGlobal: false,
      ads: [],
      description: '',
      longHeadline: '',
      url: '',
      countriesWithLanguage: ['ZH_CN', 'HKCN', 'SGCN', 'TW']
    },
    {
      id: 17,
      name: 'Greek',
      isGlobal: false,
      ads: [],
      description: '',
      longHeadline: '',
      url: '',
      countriesWithLanguage: ['EL', 'GR']
    },
    {
      id: 18,
      name: 'Hungarian',
      isGlobal: false,
      ads: [],
      description: '',
      longHeadline: '',
      url: '',
      countriesWithLanguage: ['HU']
    },
    {
      id: 19,
      name: 'Filipino',
      isGlobal: false,
      ads: [],
      description: '',
      longHeadline: '',
      url: '',
      countriesWithLanguage: ['TL']
    },
    {
      id: 20,
      name: 'Polish',
      isGlobal: false,
      ads: [],
      description: '',
      longHeadline: '',
      url: '',
      countriesWithLanguage: ['PL']
    },
    {
      id: 21,
      name: 'Thai',
      isGlobal: false,
      ads: [],
      description: '',
      longHeadline: '',
      url: '',
      countriesWithLanguage: ['TH']
    },
    {
      id: 22,
      name: 'Turkish',
      isGlobal: false,
      ads: [],
      description: '',
      longHeadline: '',
      url: '',
      countriesWithLanguage: ['TR']
    },
    {
      id: 23,
      name: 'Russian',
      isGlobal: false,
      ads: [],
      description: '',
      longHeadline: '',
      url: '',
      countriesWithLanguage: ['RU']
    },
    {
      id: 24,
      name: 'Indonesian',
      isGlobal: false,
      ads: [],
      description: '',
      longHeadline: '',
      url: '',
      countriesWithLanguage: ['ID']
    },
    {
      id: 25,
      name: 'Australia',
      isGlobal: false,
      ads: [],
      description: '',
      longHeadline: '',
      url: '',
      countriesWithLanguage: ['AU']
    },
    {
      id: 25,
      name: 'Philipines',
      isGlobal: false,
      ads: [],
      description: '',
      longHeadline: '',
      url: '',
      countriesWithLanguage: ['PHPH']
    }
  ];

  currentTemplate = this.template[0];

  form = null;

  isForced = false
  changeEmptyTemplate = false;
  forceDomainTemplate = false;

  schema = null

  changeTemplate (id) {
    this.currentTemplate = this.template[id]
    // this.getAffectedCountries()
  }

  clearInputs (geoId) {
    for (let i = 0; i < this.form.geos[geoId].ads.length; i++) {
      this.form.geos[geoId].ads[i].video = ''
      this.form.geos[geoId].ads[i].name = ''
      this.form.geos[geoId].ads[i].description = ''
      this.form.geos[geoId].ads[i].keywordPositive = ''
      this.form.geos[geoId].ads[i].keywordNegative = ''
      this.form.geos[geoId].ads[i].keywordPositiveMatch = ''
      this.form.geos[geoId].ads[i].keywordNegativeMatch = ''
      this.form.geos[geoId].ads[i].longHeadline = ''
      this.form.geos[geoId].url = ''
      this.form.geos[geoId].description = ''
      this.form.geos[geoId].longHeadline = ''
    }
  }

  clearTemplateInputs () {
    for (let i = 0; i < this.currentTemplate.ads.length; i++) {
      this.currentTemplate.ads[i].video = ''
      this.currentTemplate.ads[i].name = ''
    }
  }

  getCurrentTemplates () {
    let currentLangs = []
    currentLangs.push(this.template[0])
    for (let i = 0; i < this.form.geos.length; i++) {
      if (this.store.geos[i].extra) {
        if (this.template.find(o => o.countriesWithLanguage.includes(toUpper(this.store.geos[i].extra))) !== undefined) {
          currentLangs.push(this.template.find(o => o.countriesWithLanguage.includes(toUpper(this.store.geos[i].extra))))
        }
      }
    }
    currentLangs = [...new Set(currentLangs)]
    return currentLangs
  }

  getAffectedCountries () {
    const languageList = []
    for (let i = 0; i < this.form.geos.length; i++) {
      if (this.currentTemplate.countriesWithLanguage.includes(toUpper(this.store.geos[i].extra))) {
        languageList.push(toUpper(this.store.geos[i].extra))
      }
    }
    return languageList
  }

  mounted () {
    for (let i = 0; i < this.template.length; i++) {
      this.template[i] = new AdData(this.store.settings.amount, null, this.template[i].name, this.template[i].isGlobal, this.template[i].countriesWithLanguage, this.template[i].id).template()
    }
    this.changeTemplate(0)
    this.form = new AdData(this.store.settings.amount, this.geos).form(this.store.ads)
    this.changeSchema()
    this.applyTemplate()
  }

  /**
   * Hacky solution
   */
  @Watch('isForced')
  changeSchema () {
    if (!this.isForced) {
      this.schema = object().shape({
        geos: array().of(object().shape({
          url: string().notRequired().label('Url').nullable(),
          description: string().notRequired().label('Description').nullable(),
          longHeadline: string().notRequired().label('Long Headline').nullable(),
          ads: array().of(object().shape({
            name: string().notRequired().label('Name').nullable(),
            video: string().notRequired().label('Video').nullable()
          }))
        }))
      })
    } else {
      this.schema = object().shape({
        geos: array().of(object().shape({
          url: string().notRequired().label('Url').nullable(),
          description: string().notRequired().label('Description').nullable(),
          longHeadline: string().notRequired().label('Long Headline').nullable(),
          ads: array().of(object().shape({
            name: string().notRequired().label('Name').nullable(),
            video: string().notRequired().label('Video').nullable()
          }))
        }))
      })
    }
  }

  @Watch('form', { deep: true })
  handleForm () {
    // TODO: has no default?
    let allAds = []
    for (let index = 0; index < this.form.geos.length; index++) {
      for (let adIndex = 0; adIndex < this.form.geos[index].ads.length; adIndex++) {
        if ((this.form.geos[index].ads[adIndex].description !== this.form.geos[index].description && this.form.geos[index].ads[adIndex].description !== '') ||
          (this.form.geos[index].ads[adIndex].longHeadline !== this.form.geos[index].longHeadline && this.form.geos[index].ads[adIndex].longHeadline !== '')) {
          allAds.push(new AdData(this.store.settings.amount, this.geos).transformFilled(this.form, this.form.geos[index].ads[adIndex], this.form.geos[index]))
        } else {
          if (this.form.geos[index].ads[adIndex].name !== '') {
            allAds.push(new AdData(this.store.settings.amount, this.geos).transform(this.form, this.form.geos[index].ads[adIndex], this.form.geos[index]))
          }
        }
      }
    }
    allAds = allAds.flat()
    this.store.ads = allAds
  }

  fetchYoutubeTemplateTitle (adIndex) {
    const video = this.currentTemplate.ads[adIndex].video
    const youtube = new Youtube(video)
    const name = this.currentTemplate.ads[adIndex].name

    if (youtube.isValid() && (name === '' || name === null)) {
      youtube.getTitle().then((title) => {
        if (title.error === true) {
          setTimeout(() => this.fetchYoutubeTemplateTitle(adIndex), 3000)
        } else {
          this.currentTemplate.ads[adIndex].name = title
        }
      })
    }
  }

  fetchYoutubeTitle (geoIndex, adIndex) {
    const video = this.form.geos[geoIndex].ads[adIndex].video
    const youtube = new Youtube(video)
    const name = this.form.geos[geoIndex].ads[adIndex].name
    if (youtube.isValid() && (name === '' || name === null)) {
      youtube.getTitle().then((title) => {
        if (title.error === true) {
          setTimeout(() => this.fetchYoutubeTitle(geoIndex, adIndex), 3000)
        } else {
          this.form.geos[geoIndex].ads[adIndex].name = title
        }
      })
    }
  }

  applyTemplate () {
    const url = new UrlTools()
    for (let index = 0; index < this.store.geos.length; index++) {
      // if (this.store.geos[index].language) {
      if (this.currentTemplate.id === 0 || this.currentTemplate.countriesWithLanguage.includes(toUpper(this.store.geos[index].extra))) {
        if (this.currentTemplate.url !== '') {
          this.form.geos[index].url = url.swapUrl(this.form.geos[index].url, this.currentTemplate.url, this.forceDomainTemplate)
        }
        for (let i = 0; i < this.form.geos[index].ads.length; i++) {
          if (this.currentTemplate.description !== '') {
            this.form.geos[index].ads[i].description = this.currentTemplate.description
          }
          if (this.currentTemplate.longHeadline !== '') {
            this.form.geos[index].ads[i].longHeadline = this.currentTemplate.longHeadline
          }
        }
        if (this.currentTemplate.id === 0 || this.currentTemplate.countriesWithLanguage.includes(toUpper(this.store.geos[index].extra))) {
          this.form.geos[index].ads = this.changeAds(this.form.geos[index].ads, this.currentTemplate.ads) // Hack to avoid two-way binding, Been working on this stuff for 3 days to find a better solution so fuck it.
        }
        // }
      }
    }
  }

  changeAds (ads, templateAds) {
    if (this.changeEmptyTemplate === true) {
      return JSON.parse(JSON.stringify(templateAds))
    }
    let index = 0

    for (const ad of ads) {
      ad.name = 'searchadTest'
      ad.video = 'searchvidTest'
      ad.description = (templateAds[index].description === '' || templateAds[index].description === undefined) ? ad.description : templateAds[index].description
      ad.longHeadline = (templateAds[index].longHeadline === '' || templateAds[index].longHeadline === undefined) ? ad.longHeadline : templateAds[index].longHeadline
      index++
    }

    return JSON.parse(JSON.stringify(ads))
  }
}
