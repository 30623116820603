




































import { Vue, Component, Prop } from 'vue-property-decorator'
import { parse, isDate, format } from 'date-fns'
import { OverlayButton, Validation, Textbox, Functions, SubTitle, Title, Errors, Datefield, Dropdown, UnfoldedDatefield } from '../../../lib/mavejs'
import { object, date, number, string } from 'yup'
import { SearchCampaign } from '@/interfaces/form/SearchCampaign'
import { Obfuscator } from '@/classes/campaign/Obfuscator'
import { ShowCpcDropdown } from '@/classes/campaign/showCpcDropdown'

@Component({
  components: { OverlayButton, Validation, Textbox, SubTitle, Title, Datefield, Dropdown, UnfoldedDatefield },
  mixins: [Functions, Errors],
  methods: { format: format }
})
export default class SearchCampaignForm extends Vue {
  @Prop() store!: SearchCampaign;

  model = this.store.campaign

  schema = object({
    name: string().required(),
    description: string().nullable(),
    comment: string().nullable(),
    bidStrategy: string().nullable(),
    campaignType: number().nullable(),
    customCta: string().nullable(),
    customDescription: string().nullable(),
    product: string().required(),
    cpa: number().min(0).required().typeError('Must be a number'),
    maxCpc: number().nullable(),
    budget: number().min(0).required().typeError('Must be a number'),
    date: date().transform((value, originalValue) => isDate(originalValue) ? originalValue : parse(originalValue, 'yyyy-MM-dd', new Date())).typeError('Must be valid date'),
    creator: string().required()
  })

  handleDate (value) {
    this.model.date = format(value, 'yyyy-MM-dd')
  }

  obfuscate (product) {
    const obfuscator = new Obfuscator(product)

    this.model.name = obfuscator.obfuscate()
  }

  mounted () {
    const checkCpaDrop = new ShowCpcDropdown()
    checkCpaDrop.checkDropdownValue()
  }
}

