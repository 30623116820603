






























































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { object, number, array } from 'yup'
import { SubTitle, Title, Label, Nested, Dropdown, SearchSelect, CheckGroup, Check, Validation, RadioGroup, SearchTreeSelect, Errors } from '../../../lib/mavejs'
import { Functions } from '../../../mixins/Functions'
import { SearchCampaign } from '@/interfaces/form/SearchCampaign'

@Component({
  components: { SubTitle, Title, Nested, Label, Dropdown, Validation, SearchSelect, CheckGroup, Check, RadioGroup, SearchTreeSelect },
  mixins: [Functions, Errors]
})
export default class SearchTargetingForm extends Vue {
  @Prop() store!: SearchCampaign;
  model = this.store.targeting
  count = 1

  schema = object({
    audiencesGrouping: number().oneOf([1, 0], 'Grouping must be enabled or disabled'),
    audiences: array().required(),
    topicsGrouping: number().oneOf([1, 0], 'Grouping must be enabled or disabled'),
    topics: array().required(),
    devices: array().min(1).required('Needs atleast one selected device'),
    networks: array().nullable(),
    timezones: number().oneOf([1, 0], 'Timezones must be enabled or disabled'),
    // inventoryType: number().oneOf([2, 1, 0], 'Needs one Inventory Type'),
    genderGrouping: number().oneOf([1, 0], 'Grouping must be enabled or disabled'),
    genders: array().min(1).required('Needs atleast one selected gender'),
    ageGrouping: number().oneOf([1, 0], 'Grouping must be enabled or disabled'),
    ages: array().min(1).required('Needs atleast one selected age'),
    parentalGrouping: number().oneOf([1, 0], 'Grouping must be enabled or disabled'),
    parentals: array().min(1).required('Needs atleast one selected parental')
  })

  mounted () {
    this.count = this.calcCampaignCount()
  }

  isFalse (item) {
    return (item === false || item === 'false' || item === 0 || item === '0')
  }

  calcCampaignCount () {
    const items = [
      { grouping: 'audiencesGrouping', data: 'audiences' },
      { grouping: 'topicsGrouping', data: 'topics' },
      { grouping: 'genderGrouping', data: 'genders' },
      { grouping: 'householdIncomesGrouping', data: 'householdIncomes' },
      { grouping: 'ageGrouping', data: 'ages' },
      { grouping: 'parentalGrouping', data: 'parentals' },
      { grouping: 'devicesGrouping', data: 'devices' }
    ]

    let count = 1

    for (const item of items) {
      if (this.isFalse(this.model[item.grouping]) && this.model[item.data].length !== 0) {
        count = count * parseInt(this.model[item.data].length)
      }
    }

    return count
  }

  @Watch('model', { deep: true })
  handleModelChange () {
    this.count = this.calcCampaignCount()
  }
}
